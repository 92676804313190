<template>
	<div class="card">
		<div class="card-body">
			<div class="row">
				<div class="col-md-4">
					<div class="form-group">
                        <!-- <div style="width:200">
                            <img :src="user.photo" alt="." width="200" >
                            <button class="btn btn-info mt-2 btn-block">
                                Change Photo
                            </button>
                        </div> -->
						<croppa
							v-model="myCroppa"
							replace-drop
							ref="myCroppa"
							accept="image/*"
							placeholder="Preview Image"
							:width="200"
							:height="200"
							:quality="2"
							:prevent-white-space="true"
							:show-remove-button="false"
							:initial-image="initialImage"
							@file-choose="displayRefresh"
						/>
						<button
							class="btn btn-warning btn-block"
							type="button"
							@click="selectPhoto"
							:style="`width: 200px !important;`"
						>
							Select Photo
						</button>
						<button
							class="btn btn-success btn-block"
							type="button"
							v-if="!!isSelected"
							@click="savePhoto"
							:style="`width: 200px !important;`"
							:disabled="isUploading"
						>
							Save Photo
							<Loader v-if="isUploading" />
						</button>
					</div>
				</div>
				<div class="col-md-8">
					<form @submit.prevent="updateUser">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label for="">Full name</label>
									<input
										type="text"
										class="form-control"
										v-model="user.fullname"
										required
									/>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="">Email</label>
									<input
										type="email"
										class="form-control"
										v-model="user.email"
										required
									/>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="">Telephone</label>
									<input
										type="tel"
										class="form-control"
										v-model="user.telephone"
										required
									/>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="">Date of Birth</label>
									<Datetime
										v-model="user.birthday"
										:flow="['year', 'month', 'date']"
										input-class="form-control"
										format="yyyy-MM-dd"
										auto
									/>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="">Gender</label>
									<select
										class="form-control"
										v-model="user.gender"
										required
									>
										<option>Male</option>
										<option>Female</option>
									</select>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="">Role</label>
									<select
										class="form-control"
										v-model="user.role"
										required
									>
										<option>Administrator</option>
										<option>Finance</option>
									</select>
								</div>
							</div>
						</div>
						<button
							class="btn btn-success"
							:disabled="isProcessing"
						>
							<i class="zmdi zmdi-save"></i>
							Save User
                            <Loader v-if="isProcessing" />
						</button>
					</form>
				</div>
			</div>
			<!-- <pre>{{ user }}</pre> -->
		</div>
	</div>
</template>


<script>
import firebase from 'firebase/app';
import dayjs from 'dayjs';
export default {
    data(){
        return {
            myCroppa: {},
            isSelected: false,
            isProcessing: false,
            isUploading: false,
            user: {
                uid: this.$route.params.userId,
                fullname: "",
                email: "",
                telephone: "",
                dob: "",
                gender: "",
                role: "",
                birthday: "",
            },
            birthday: "",
            photodata: "",
            initialImage: '/img/placeholder.jpg',
        }
    },
    created(){
        this.getUserData()
    },
    methods: {

        async getUserData(){
            const userId = this.$route.params.userId
            const doc = await firebase.firestore().collection("users").doc(userId).get()
            if (doc.exists) {
                const data = doc.data()
                this.user = data
                if (data.dob && data.dob != "") {
                    const birthday = dayjs(data.dob.toDate()).toLocaleString()
                    this.user.birthday = birthday
                }
            }
        },

        async updateUser(){
            this.isProcessing = true
            if (this.birthday != "") {
                const dob = dayjs(this.birthday).toDate()
                this.user.dob = dob
            }
            const userId = this.$route.params.userId
            await firebase
                .firestore()
                .collection("users")
                .doc(userId)
                .set(this.user, { merge: true })
            this.isProcessing = false
        },

        async save(user){
            const updateUser = this.$fn.httpsCallable("updateUser")
            const response = await updateUser(user)
            this.isSelected = false
            this.$toast.success(response.data.message)
        },

        displayRefresh(){
            this.isSelected = true
        },

        selectPhoto(){
            this.$refs.myCroppa.chooseFile()
        },

        async savePhoto(){
            this.isUploading = true
            const photodata = this.$refs.myCroppa.generateDataUrl()
            if (photodata) {
            const uid = this.$route.params.userId
                const filename = 'users/' + uid + '.jpg'
                const metadata = { contentType: 'image/jpeg', }
                const task = this.$st.ref(filename).putString(photodata, 'data_url', metadata)
                task.on('state_changed',
                    () => {},
                    () => {},
                    async () => {
                        task.snapshot.ref.getDownloadURL().then(async downloadURL => {
                            const updateUserPhoto = this.$fn.httpsCallable("updateUserPhoto")
                            const response = await updateUserPhoto({ uid, photo: downloadURL })
                            this.$toast.success(response.data.message)
                            this.isUploading = false
                        })
                    }
                )
            } else {
                this.isUploading = false
            }

        },
    },
}
</script>